import React from 'react'
import Sidebar from './Sidebar'
import SimpleBookings from './SimpleBookings'

const ProfileDashboard = () => {
    return (
        <>
       
            <Sidebar />
          
          </>
           )
}

export default ProfileDashboard