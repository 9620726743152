import React, { useEffect } from 'react';

const FaceBookPixel = () => {
  useEffect(() => {
    // Function to load the Facebook Pixel script
    const loadFaceBookPixel = () => {
      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

      // Call fbq functions to initialize and track
      window.fbq('init', '1986726531744831');
      window.fbq('track', 'PageView');
    };

    loadFaceBookPixel();

    // Add noscript fallback
    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.height = '1';
    img.width = '1';
    img.style.display = 'none';
    img.src = 'https://www.facebook.com/tr?id=1986726531744831&ev=PageView&noscript=1';
    noscript.appendChild(img);
    document.body.appendChild(noscript);

    // Clean up function to remove the script and noscript tag when the component unmounts
    return () => {
      const script = document.querySelector('script[src="https://connect.facebook.net/en_US/fbevents.js"]');
      if (script) {
        script.remove();
      }
      if (noscript) {
        noscript.remove();
      }
    };
  }, []);

  return null;
};

export default FaceBookPixel;
